import { useMutation } from "@apollo/client"
import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import { GET_ACTIVE_ORDER } from "../components/ShoppingCart/ShoppingCart.vendure"
import Layout from "../layouts/RootLayout"
import { formatPrice } from "../utilities/utilities"
import styles from "./ProductDetail.module.scss"
import { ADD_ITEM_TO_ORDER } from "./ProductDetail.vendure"

export default ({ data }) => {
  const product = data.vendure.product
  const [variantId, setVariantId] = useState(product.variants[0].id)
  const [quantity, setQuantity] = useState(1)

  // const {
  //   data: { activeOrderId },
  // } = useQuery(GET_ACTIVE_ORDER_ID)
  // useQuery(GET_ACTIVE_ORDER, {
  //   onCompleted: ({ activeOrder }) => {
  //     if (activeOrder) {
  //       setOrders(activeOrder)
  //     } else {
  //       console.log("acitve order RAS")
  //     }
  //   },
  // })

  const [addToOrder] = useMutation(ADD_ITEM_TO_ORDER, {
    variables: {
      id: variantId,
      quantity,
    },
    update: (cache, mutationResult) => {
      cache.writeQuery({
        query: GET_ACTIVE_ORDER,
        data: {
          activeOrder: mutationResult.data.addItemToOrder,
        },
      })
    },
  })

  //const setActiveOrderId = useMutation(SET_ACTIVE_ORDER_ID)

  return (
    <Layout>
      {/* <!-- Page Title (Shop)--> */}
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to="/">
                    <i className="czi-home"></i>Accueil
                  </Link>
                </li>
                <li className="breadcrumb-item text-nowrap">
                  <Link to="/products">Boutique</Link>
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  {product.name}
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pr-lg-4 text-center text-lg-left">
            <h1 className="h3 text-light mb-0">{product.name}</h1>
          </div>
        </div>
      </div>

      {/* <!-- Page Content--> */}
      <div className="container">
        {/* <!-- Gallery + details--> */}
        <div className="bg-light box-shadow-lg rounded-lg px-4 py-3 mb-5">
          <div className="px-lg-3">
            <div className="row">
              {/* <!-- Product gallery--> */}
              <div className="col-lg-7 pr-lg-0 pt-lg-4">
                <div className="cz-product-gallery">
                  <div className="cz-preview order-sm-2">
                    <div className={styles.image} id="first">
                      <img
                        src={
                          product.featuredAsset.preview +
                          "?w=500&h=400&mode=crop"
                        }
                        alt="Product"
                      />
                    </div>
                  </div>
                  <div className="cz-thumblist order-sm-1">
                    <a className="cz-thumblist-item active" href="#first">
                      <img
                        className="cz-image-zoom"
                        src={product.featuredAsset.preview}
                        alt="Product image"
                      />
                    </a>
                  </div>
                </div>
              </div>

              {/* <!-- Product details--> */}
              <div className="col-lg-5 pt-4 pt-lg-0">
                <div className="product-details ml-auto pb-3">
                  <div className="d-flex justify-content-between align-items-center mb-2"></div>
                  <div className="mb-3">
                    <span className="h3 font-weight-normal text-primary mr-1">
                      {formatPrice(
                        product.variants[0].currencyCode,
                        product.variants[0].priceWithTax
                      )}
                    </span>
                  </div>
                  <div className="font-size-sm mb-4">
                    <span className="text-heading font-weight-medium mr-1">
                      Couleur:
                    </span>
                    <span className="text-muted" id="colorOption">
                      N/A
                    </span>
                  </div>
                  <div className="position-relative mr-n4 mb-3">
                    <div className="product-badge product-available mt-n1">
                      <i className="czi-security-check"></i>Disponible
                    </div>
                  </div>
                  <form
                    action="javascript:void(0);"
                    className="mb-grid-gutter"
                    method="post"
                  >
                    <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center pb-1">
                        <label
                          className="font-weight-medium"
                          for="product-size"
                        >
                          Version
                        </label>
                      </div>

                      <select
                        id="product-size"
                        className="custom-select"
                        value={variantId}
                        onChange={e => setVariantId(e.currentTarget.value)}
                      >
                        {product.variants.map(variant => (
                          <option key={variant.id} value={variant.id}>
                            {variant.name}
                            {" - "}
                            {formatPrice(
                              variant.currencyCode,
                              variant.priceWithTax
                            )}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group d-flex align-items-center">
                      <select
                        className="custom-select mr-3"
                        value={quantity}
                        onChange={e => setQuantity(+e.currentTarget.value)}
                      >
                        {Array.from({ length: 10 }).map((_, index) => (
                          <option key={index} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn btn-primary btn-shadow btn-block"
                        onClick={() => addToOrder()}
                      >
                        <i className="czi-view-list font-size-lg mr-2"></i>
                        Ajouter a ma liste
                      </button>
                    </div>
                  </form>

                  {/* <!-- Product panels--> */}
                  <div className="accordion mb-4" id="productPanels">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="accordion-heading">
                          <a
                            href="#shippingOptions"
                            role="button"
                            data-toggle="collapse"
                            aria-expanded="true"
                            aria-controls="shippingOptions"
                          >
                            <i className="czi-support text-muted lead align-middle mt-n1 mr-2"></i>
                            Référence
                            <span className="accordion-indicator">
                              <i data-feather="chevron-up"></i>
                            </span>
                          </a>
                        </h3>
                      </div>
                      <div
                        className="collapse show"
                        id="shippingOptions"
                        data-parent="#productPanels"
                      >
                        <div className="card-body font-size-sm">
                          <div className="d-flex justify-content-between border-bottom pb-2">
                            <div>
                              <div className="font-weight-semibold text-dark">
                                Art. No.
                              </div>
                            </div>
                            <div>{product.variants[0].sku}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-lg-3 pb-4 pb-sm-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h2 className="h3 pb-2">Informations produit</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            ></div>
          </div>
        </div>
      </div>
      {/* <div classNameName="container py-5 my-md-3">
        <h2 className="h3 text-center pb-4">Nos meilleures ventes</h2>
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    vendure {
      product(id: $id) {
        id
        name
        slug
        description
        featuredAsset {
          id
          preview
        }
        variants {
          id
          name
          priceWithTax
          currencyCode
          sku
        }
      }
    }
  }
`
